<template>
<v-container>
	<v-breadcrumbs :items="breadCombs" class="d-print-none"></v-breadcrumbs>
	<v-row>
		<v-col cols="12" sm="12" md="6" class="blue-grey lighten-5">
				<v-treeview
					rounded
					hoverable
					:items="categories"
					:activatable="activatable"
					:color="color"
					@update:active="updateActive"
				></v-treeview>
		</v-col>
		<v-col cols="12" sm="12" md="6">
			<div>{{$vuetify.lang.t('$vuetify.qrBind.idText')}}  {{categoryId}}</div>
			<div>{{$vuetify.lang.t('$vuetify.qrBind.nameText')}} {{categoryName}}</div>
			<div>{{$vuetify.lang.t('$vuetify.qrBind.bindTypeText')}} {{bindType}}</div>

			<v-text-field
					:label="$vuetify.lang.t('$vuetify.qrBind.resourceText')"
					v-model="resource"
			></v-text-field>
			<v-btn color="green darken-1" text @click.stop="save">{{$vuetify.lang.t('$vuetify.base.save')}}</v-btn>
		</v-col>

	</v-row>
</v-container>

</template>

<script>

export default {
		data: () => ({
			categories:[],
			activatable:true,
			color: 'red',
			resource:"",
			categoryId:null,
			categoryName:"",
			bindType:"",
			id:""
		}),
		created() {
			this.init();
		},
		computed: {
			breadCombs() {
				return [
					{
						text: this.$vuetify.lang.t('$vuetify.base.home'),
						disabled: false,
						href: '/',
					},
					{
						text: this.$vuetify.lang.t('$vuetify.base.qrBind'),
						disabled: true
					}
				]
			},
		},
		methods: {
			init(){
				this.id = this.$route.params.id;
				console.info("init data");
				this.$axios.get(
					"category/childs",
					{
						params:{
							code:"QrCode"
						}
					}
				).then((response) => {
					var data = response.data;
					if(data.code != 200){
						let message = data.message
						this.$store.dispatch('messages/addMessage', {message,messageClass: 'danger'});
						return;
					} else {
						this.categories = data.data;
					}
				})
			},
			updateActive(categoryIds){
				console.info(categoryIds);

				this.categoryId = categoryIds[0];
				var categories = [];
				for (var j =  this.categories.length - 1; j >= 0; j--) {
					categories.push(this.categories[j]);
				}
				while(categories.length != 0){
					var category = categories.pop();
					if(category.id == categoryIds[0]) {
						this.categoryName = category.name;
						this.bindType = category.bindType;
						return;
					} 
					var children = category.children;
					if(children != null){
						for (var i = children.length - 1; i >= 0; i--) {
							categories.push(children[i]);
						}
					}
				}
			},
			isLeaf(categoryId) {
				var categories = [];
				for (var j =  this.categories.length - 1; j >= 0; j--) {
					categories.push(this.categories[j]);
				}
				while(categories.length != 0){
					var category = categories.pop();
					if(category.id == categoryId) {
						var children = category.children;
						if(children != null && children.length > 0){
							return false;
						}
						return true
					} 
					var children1 = category.children;
					if(children1 != null){
						for (var i = children1.length - 1; i >= 0; i--) {
							categories.push(children1[i]);
						}
					} 
				}
			},
			save(){
				var categoryId = this.categoryId;
				if(!this.isLeaf(categoryId)){
					let message = "is not leaf";
					this.$store.dispatch('messages/addMessage', {message,messageClass: 'danger'});
					return;
				}
				this.$axios.post(
					"qrcode/bind",
					{
						"id" : this.id,
						"bindType":this.bindType,
						"categoryId": this.categoryId,
						"resource" : this.resource
					}
				).then((response) => {
					if(response.data.code != 200){
						let message = response.data.message;
						this.$store.dispatch('messages/addMessage', {message,messageClass: 'danger'});
						return;
					} else {
						this.$axios.get(
							"qrcode/detail?codeInfo=" + this.id
						).then((response1) => {
							var data1 = response1.data;
							if(data1.code != 200){
								let message = data1.message
								this.$store.dispatch('messages/addMessage', {message,messageClass: 'danger'});
								return;
							} else {
								let returnData = data1.data;
								if(returnData.used){
									console.info("to qrInfo");
									window.location.href = returnData.redirectUrl;
								} else {
									console.info("to compelete qrInfo");
									window.location.href = "#/secure/qrcode/bind/" + returnData.id;
								}
							}
						}).catch((error) => {
							console.error(error)
						});
					}
				}).catch((error) => {
					console.error(error)
				});


			}
		}
	}
		
</script>